import { format, parseISO } from 'date-fns'
import logger from 'utils/logger'

function formatDate (date, formatString) {
  if (!date) return ''

  try {
    if (typeof date === 'string') {
      return format(parseISO(date), formatString)
    }

    return format(date, formatString)
  } catch (e) {
    logger.captureException(`[formatDate]`, e)
    logger.captureBreadcrumb({ date, formatString })
  }
}

export default formatDate
