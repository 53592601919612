import React from 'react'

import VirtualList from 'react-tiny-virtual-list'
import { throttle, noop } from 'lodash'

import Loader from 'components/Loader'

export class SmallLoader extends React.Component {
  shouldComponentUpdate () {
    return false
  }

  render () {
    return (
      <div className='flex flex-row justify-center items-center h-100 relative'>
        <Loader width='25px' height='35px' />
      </div>
    )
  }
}

class InfiniteList extends React.Component {
  static defaultProps = {
    className: '',
    height: 300,
    width: 300,
    itemHeight: 30,
    reverse: false,
    data: [],
    hasMore: false,
    loadMore: noop,
    loading: false,
    renderItem: noop,
    endReachedThreshold: 0.55,
    throttleDelay: 250,
    scrollToIndex: undefined,
    renderEmptyComponent: () => <p className='mb0'>No results!</p>,
    alwaysScrollToLatest: false
  }

  constructor (props) {
    super(props)

    this.state = {
      scrollToIndex: props.scrollToIndex
    }
  }

  prevOffset = 0

  componentDidMount () {
    const { data, loadMore, hasMore, loading } = this.props

    if (hasMore && data.length === 0 && !loading) {
      loadMore()
    }
  }

  componentDidUpdate (prevProps) {
    if (typeof this.props.scrollToIndex === 'undefined') {
      if (
        this.props.alwaysScrollToLatest &&
        prevProps.data.length !== this.props.data.length
      ) {
        this.setState({ scrollToIndex: this.props.data.length - 1 })
      } else if (prevProps.data.length > this.props.data.length) {
        // Lenght is decreased. Resetting prevOffset
        this.prevOffset = 0

        if (this.props.data.length > 0) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ scrollToIndex: 0 })
        }
      } else {
        if (this.state.scrollToIndex > -1) {
          // eslint-disable-next-line react/no-did-update-set-state
          this.setState({ scrollToIndex: undefined })
        }
      }
    } else if (prevProps.scrollToIndex !== this.props.scrollToIndex) {
      this.setState({
        scrollToIndex: this.props.scrollToIndex
      })
    }

    if (this.props.extraData !== prevProps.extraData) {
      if (this.virtualList) {
        this.virtualList.recomputeSizes()
        this.virtualList.forceUpdate()
        this.forceUpdate()
      }
    }

    if (
      this.props.data.length === 0 &&
      this.props.hasMore &&
      !this.props.loading
    ) {
      this.props.loadMore()
    }
  }

  getItemHeights = (average = false) => {
    const { itemHeight, data } = this.props

    if (typeof itemHeight === 'function') {
      const heights = data.map((_, i) => itemHeight(i))
      const total = heights.reduce((a, b) => a + b, 0)

      if (average) {
        return Math.ceil(total / data.length)
      }

      return total
    }

    return itemHeight
  }

  recoverBottomScrollPos = (scrollOffset, totalHeight) => {
    const bottomOffset = totalHeight - scrollOffset
    const updatedTotalHeight = this.getItemHeights()
    const scroll = updatedTotalHeight - bottomOffset

    this.virtualList.rootNode.scrollTop = scroll
  }

  handleScroll = throttle(
    scrollOffset => {
      const { hasMore, loading } = this.props

      if (loading || !hasMore) {
        return
      }

      const { loadMore, reverse, endReachedThreshold, height } = this.props

      const totalHeight = this.getItemHeights()

      let thresholdReached, scrollingTowardsEnd

      if (reverse) {
        thresholdReached =
          endReachedThreshold * totalHeight >= scrollOffset + height
        scrollingTowardsEnd = scrollOffset < this.prevOffset
      } else {
        thresholdReached =
          endReachedThreshold * totalHeight <= scrollOffset + height
        scrollingTowardsEnd = scrollOffset > this.prevOffset
      }

      if (thresholdReached && scrollingTowardsEnd) {
        loadMore(() => {
          if (reverse) {
            this.recoverBottomScrollPos(scrollOffset, totalHeight)
          }
        })
      }

      this.prevOffset = scrollOffset
    },
    this.props.throttleDelay,
    { leading: false, trailing: true }
  )

  _renderItem = ({ index, style }) => {
    const { data, renderItem, hasMore, reverse } = this.props

    const loading = reverse ? index === 0 : index === data.length - 1

    if (hasMore && loading) {
      return (
        <div key={index} style={style}>
          <SmallLoader />
        </div>
      )
    }

    const item = data[index]

    return (
      <div key={index} style={style}>
        {renderItem({ item, index })}
      </div>
    )
  }

  render () {
    const {
      className,
      width,
      height,
      itemHeight,
      data,
      loading,
      renderEmptyComponent,
      scrollToAlignment,
      onItemsRendered
    } = this.props

    if (data.length === 0) {
      return (
        <div
          style={{ width, height }}
          className={`flex items-center justify-center ${className}`}
        >
          {loading ? <SmallLoader /> : renderEmptyComponent()}
        </div>
      )
    }

    return (
      <VirtualList
        className={`scrollbar pb4 ${className}`}
        ref={x => (this.virtualList = x)}
        width={width}
        height={height}
        itemCount={data.length}
        itemSize={itemHeight}
        renderItem={this._renderItem}
        onScroll={this.handleScroll}
        scrollToIndex={this.state.scrollToIndex}
        scrollToAlignment={scrollToAlignment}
        onItemsRendered={onItemsRendered}
      />
    )
  }
}

export default InfiniteList
